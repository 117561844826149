import styles from "./loading-dots.module.css";
export const LoadingDots = ({
  color = "#000"
}: {
  color?: string;
}) => {
  return <span className={styles.loading} data-sentry-component="LoadingDots" data-sentry-source-file="loading-dots.tsx">
      <span style={{
      backgroundColor: color
    }} />
      <span style={{
      backgroundColor: color
    }} />
      <span style={{
      backgroundColor: color
    }} />
    </span>;
};