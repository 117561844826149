"use client";

import { useState } from "react";

import { api } from "~/trpc/react";

import { useUploadToObjectStorage } from "~/lib/hooks/useUploadToObjectStorage";

export interface FileUploadMetadata {
  ownerId?: string | undefined;
  ownerType?:
    | "user"
    | "organization"
    | "post"
    | "project"
    | "event"
    | "text-editor"
    | "widget"
    | undefined;
  makePublic?: boolean | undefined;
}

export interface FileEntityType {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  userId: string;
  organizationId: string;
  owner_id: string;
  owner_type: string;
  bucket: string;
  filename: string;
  originalFilename: string;
  size: number;
  contentType: string;
}
export interface StoreFileResult {
  file: FileEntityType | undefined;
  publicUrl: string;
}

export interface MutateAsyncResultTypeSuccessType extends FileEntityType {
  publicUrl: string;
}

export type MutateAsyncReturnType =
  | { error: Error; result?: undefined }
  | { error?: undefined; result: MutateAsyncResultTypeSuccessType };

export interface UploadToObjectStorageAndStoreFileProps {
  file: File;
  metadata: FileUploadMetadata;
}

export const useUploadToObjectStorageAndStoreFile = () => {
  const [error, setError] = useState<Error | null>(null);
  const [result, setResult] = useState<FileEntityType | null>(null);

  const uploadFileMutation = useUploadToObjectStorage();
  const storeFileInDatabaseMutation = api.files.createOne.useMutation();

  function __internal_makeError(message: string) {
    const error = new Error(message);
    setError(error);
    setResult(null);
    return error;
  }

  const mutateAsync = async ({
    file,
    metadata,
  }: UploadToObjectStorageAndStoreFileProps): Promise<MutateAsyncReturnType> => {
    const uploadFileResult = await uploadFileMutation.mutateAsync({
      file,
    });

    if (uploadFileResult.error) {
      return {
        error: __internal_makeError(uploadFileResult.error.message),
      };
    }

    const storeFileResult = await storeFileInDatabaseMutation.mutateAsync({
      file: {
        name: uploadFileResult.result.filename,
        originalName: uploadFileResult.result.originalFilename,
        type: file.type,
        size: file.size,
        ownerId: metadata.ownerId,
        ownerType: metadata.ownerType,
      },
      makeFileAccessibleToPublic: metadata.makePublic ?? false,
    });

    if (!storeFileResult.file) {
      return {
        error: __internal_makeError(
          "Beim Speichern des Bildes in der Datenbank ist ein Fehler aufgetreten. Bitte laden Sie die Seite neu und versuchen Sie es erneut.",
        ),
      };
    }
    setError(null);
    setResult(storeFileResult.file);
    return {
      result: {
        ...storeFileResult.file,
        publicUrl: storeFileResult.publicUrl,
      },
    };
  };

  return {
    mutateAsync,
    error,
    result,
  };
};
