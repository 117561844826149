"use client";

import { useState } from "react";

import { uploadToS3 } from "~/lib/storage/uploadToS3";
import { api } from "~/trpc/react";

export interface StoredFileData {
  key: string;
  filename: string;
  url: string;
  originalFilename: string;
  type: string;
  size: number;
}

export interface UploadToObjectStorageProps {
  file: File;
}

export const useUploadToObjectStorage = () => {
  const [error, setError] = useState<Error | null>(null);
  const [result, setResult] = useState<StoredFileData | null>(null);

  const createPresignedUploadUrl = api.files.createPresignedUrl.useMutation();

  function __internal_make_Error(message: string) {
    const error = new Error(message);
    setError(error);
    setResult(null);

    return error;
  }

  const mutateAsync = async ({ file }: UploadToObjectStorageProps) => {
    const result = await createPresignedUploadUrl.mutateAsync({
      file: {
        key: `${file.name}+${file.size}`,
        name: file.name,
        type: file.type,
        size: file.size,
      },
    });

    if (!result.presignedUrlResponse) {
      return {
        error: __internal_make_Error(
          "Beim Speichern des Bildes auf dem Server ist ein Fehler aufgetreten. Bitte laden Sie die Seite neu und versuchen Sie es erneut.",
        ),
      };
    }

    const fileData: StoredFileData = result.presignedUrlResponse;

    const uploadedFileResults = await uploadToS3({ url: fileData.url }, file);

    if (uploadedFileResults.status != 200) {
      return {
        error: __internal_make_Error(
          "Beim Speichern des Bildes auf dem Daten Server ist ein Fehler aufgetreten. Bitte laden Sie die Seite neu und versuchen Sie es erneut.",
        ),
      };
    }

    setError(null);
    setResult(fileData);
    return {
      result: fileData,
    };
  };

  return {
    mutateAsync,
    result,
    error,
  };
};
